import { useCallback, useState } from 'react';
import { AccordionItem } from 'src/components/ui/UIAccordion/UIAccordion';
import { UIPopup } from 'src/components/ui/UIPopup/UIPopup';
import { UISpinner } from 'src/components/ui/UISpinner/UISpinner';
import { ICommonItems, IRenderType, TAccordionShort, TProjectObject } from 'src/types/LandingMapTypes';
import styles from './LandingMapPopup.module.css';
interface ILandingMapPopupProps<T> extends IRenderType<T> {
  items: T[];
  onClosePopup: () => void;
  textVariants: string[];
  isLoading: boolean;
  setObjects?: (objs: TProjectObject[]) => void;
}

function LandingMapPopup<T extends ICommonItems>({
  items: initialItems,
  onClosePopup,
  renderAccoHeaderContent,
  renderAccoBodyContent,
  textVariants,
  isLoading,
  setObjects,
}: ILandingMapPopupProps<TAccordionShort<T>>) {
  const [items, setItems] = useState(initialItems);
  const isExpanded = items.some((item) => item.isOpen);

  const toggleAccordion = useCallback(
    (id: number): void => {
      const newItems = items.map((item) => ({
        ...item,
        isOpen: item.ndxId == id ? !item.isOpen : false,
      }));
      setItems(newItems);
    },
    [items],
  );

  const onToggleActive = useCallback(
    (id: number): void => {
      const newItems = items.map((item) => ({
        ...item,
        isActive: item.ndxId == id ? !item.isActive : false,
      }));
      setItems(newItems);
    },
    [items],
  );

  const atLeastOneActive = items.some((item) => item.isActive);

  return (
    <UIPopup<TAccordionShort<T>>
      onClosePopup={onClosePopup}
      items={items}
      isExpanded={isExpanded}
      textVariants={textVariants}
    >
      {isLoading ? (
        <div className={styles.loaderWrap}>
          <UISpinner isLoading={isLoading} />
        </div>
      ) : (
        items.map((item) => (
          <AccordionItem<TAccordionShort<T>>
            setObjects={setObjects}
            noneShown={!atLeastOneActive}
            // NO item.id
            key={item.ndxId}
            item={item}
            onToggle={toggleAccordion}
            onToggleActive={onToggleActive}
            renderAccoHeaderContent={renderAccoHeaderContent}
            renderAccoBodyContent={renderAccoBodyContent}
          />
        ))
      )}
    </UIPopup>
  );
}

export default LandingMapPopup;
