import { api } from 'src/api/api';

export enum EAppealStatus {
  COMPLETED_MODERATOR_DECLINE = 'COMPLETED_MODERATOR_DECLINE',
  COMPLETED_SIGNING_APPROVE_RESPONSE = 'COMPLETED_SIGNING_APPROVE_RESPONSE',
  COMPLETED_EDMS_RESPONSE_SENT_TO_APPLICANT = 'COMPLETED_EDMS_RESPONSE_SENT_TO_APPLICANT',
  COMPLETED_ESCALATE_REJECTED = 'COMPLETED_ESCALATE_REJECTED',
}

export enum EAppealStatusText {
  COMPLETED = 'Рассмотрено',
  IN_PROGRESS = 'В работе',
}

export enum EEntityType {
  appeals = 'appeals',
  projects = 'projects',
}

export enum EGetType {
  getAppealsMap = 'getAppealsMap',
  getProjectsMap = 'getProjectsMap',
}

export interface ICommonItems {
  id: number;
  location?: string;
  subjectName: string;
  stage: string;
  objectId?: number;
}

export interface IRenderType<T> {
  renderAccoHeaderContent: (v: T) => React.ReactNode;
  renderAccoBodyContent: (v: T) => React.ReactNode;
}

export enum EProjectStatus {
  'NEW' = 'NEW',
  'OPEN' = 'OPEN',
  'IN_WORK' = 'IN_WORK',
  'POSTPONED' = 'POSTPONED',
  'REJECTED' = 'REJECTED',
  'COMPLETED' = 'COMPLETED',
  'CLOSED' = 'CLOSED',
}
export type TProjectStepUpdatedFieldKey =
  | 'NAME'
  | 'DESCRIPTION'
  | 'PROBLEM'
  | 'CAUSE'
  | 'TYPE'
  | 'CONFIDENTIAL'
  | 'OPA_ID'
  | 'OPA_NAME'
  | 'ROOT_OPA_ID'
  | 'ROOT_OPA_NAME'
  | 'MANAGED_BY_USER_ID'
  | 'MANAGED_BY_USER_NAME'
  | 'REGISTRATION_DATE'
  | 'START_DATE'
  | 'DECIDE_UNTIL_DATE'
  | 'PROJECT_STATUS'
  | 'FUND_VALUE'
  | 'FUND_SOURCE'
  | 'PROGRESS'
  | 'RESULT'
  | 'PRIORITY'
  | 'REGION_IDS'
  | 'MUNICIPALITY_IDS'
  | 'NEW_ATTACHMENT_IDS'
  | 'DELETED_ATTACHMENT_IDS'
  | 'OBJECT_IDS'
  | 'APPEAL_IDS'
  | 'INCIDENT_IDS'
  | 'JOIN_CURRENT_TO_PROJECT_ID'
  | 'JOIN_PROJECT_ID_TO_CURRENT';

export type TProjectObject = {
  id: number;
  name: string;
  shortName: string;
  address: string;
  lat: number;
  lon: number;
};

export type TProjectStepUpdatedField = {
  id: number;
  projectStep: any; // type this
  fieldKey: TProjectStepUpdatedFieldKey;
  fieldValue: string;
};

export type TProjectStep = {
  id: number;
  stepNumber: number;
  actionType: string;
  updatedFields: TProjectStepUpdatedField[];
};

export interface IProject extends ICommonItems {
  state: EProjectStatus;
  regDate: string;
  name: string;
  explanation: string;
  problem: string;
  cause: string;
  type: string;
  confidential: boolean;
  opa: string;
  executor: string;
  decideUntilDate: string;
  progress: number;
  result: string;
  startDate: string;
  appealNum: number;
  objects: TProjectObject[];
  steps: TProjectStep[];
}

export interface IShortAppeal extends ICommonItems {
  answer?: string;
  description: string;
  status: string;
}

export type TAccordionShort<T> = T & { isOpen: boolean; ndxId: number; isActive: boolean; noneShown?: boolean };

export interface IPoint {
  id: number;
  coordinates: number[];
}

export interface IAppealIds {
  [key: string]: string;
}

export interface IAppealsLocation {
  appealIds: IAppealIds[];
  coordinates: string;
}

export interface IRegion {
  coordinates: string;
  id: number;
  name: string;
  okato: string;
}

export interface IZoom {
  newZoom: number;
  oldZoom: number;
}

export interface AnyObject {
  [key: string]: any;
}

export type TApiKeys = keyof typeof api;
export type TApiPublicProperties = Exclude<TApiKeys, TApiKeys & Function>;
export type TBaseItemType = { id: number; projectId: number; lat: number; lon: number };
export type ExtendType<T extends TBaseItemType> = {
  type: 'Feature';
  id: T['id'];
  geometry: {
    type: 'Point';
    coordinates: (T['lat'] | T['lon'])[];
  };
  options: {
    source: T;
  };
};

export type TCoords = number[][];
export type TEntityRef = { isCluster: boolean; objects?: any; objectId: 0; type: EEntityType };
export type TEntityItems<A = IShortAppeal, P = IProject> = {
  [EEntityType.appeals]: A[];
  [EEntityType.projects]: P[];
};

export type TEntityPopup = {
  [EEntityType.appeals]: boolean;
  [EEntityType.projects]: boolean;
};

export type TIds = { [key: string]: number[] };
