import { ICommonItems, IRenderType, TAccordionShort, TProjectObject } from '../../../types/LandingMapTypes';
import { IconDropdown } from '../../icons/IconDropdown';
import { IconDropdownUp } from '../../icons/IconDropdownUp';
import styles from './UIAccordion.module.css';

interface IAccordionProps<T> extends IRenderType<T> {
  item: TAccordionShort<T>;
  onToggle?: (id: number) => void;
  onToggleActive?: (id: number) => void;
  setObjects?: (objs: TProjectObject[]) => void;
  noneShown: boolean;
}

export function AccordionItem<T extends ICommonItems>({
  onToggle,
  onToggleActive,
  item,
  renderAccoHeaderContent,
  renderAccoBodyContent,
  setObjects,
  noneShown,
}: IAccordionProps<T>) {
  return (
    <div className={styles.accordion}>
      <div className={styles.accordionHeader}>
        <div className={styles.accordionTitle}>
          {renderAccoHeaderContent({ ...item, setObjects, onToggleActive, noneShown })}
        </div>
        <div className={styles.accordionCloseIcon} onClick={() => onToggle?.(item.ndxId)}>
          {item.isOpen ? <IconDropdownUp /> : <IconDropdown />}
        </div>
      </div>
      {item.isOpen && <div className={styles.accordionDropdown}>{renderAccoBodyContent({ ...item })}</div>}
    </div>
  );
}
