import qs from 'qs';
import { api } from 'src/api/api';
import {
  EEntityType,
  EGetType,
  ExtendType,
  IProject,
  TApiPublicProperties,
  TBaseItemType,
} from 'src/types/LandingMapTypes';

export function debounce(func: Function, delay: number): Function {
  let timeoutId: NodeJS.Timeout | null = null;

  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId);
    // @ts-ignore
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  };
}

export function declOfNum(number: number, words: string[]): string {
  return words[
    number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
}

export const paramsSerializer = <T>(params: T) => qs.stringify(params, { arrayFormat: 'repeat' });

export const getRectCoords = (coords: number[][]) => ({
  topLeftLat: coords[1][0],
  topLeftLon: coords[0][1],
  bottomRightLat: coords[0][0],
  bottomRightLon: coords[1][1],
});

export const createFeatureTypes = <T extends TBaseItemType>(data: T[]): ExtendType<T>[] =>
  data.map((item: T) => ({
    type: 'Feature',
    id: item.id,
    geometry: {
      type: 'Point',
      coordinates: [item.lat, item.lon],
    },
    options: {
      source: item,
    },
  }));

export const setClusterIcons = (pinArg: string, offset: number[] = [-30, -30]) => [
  {
    href: pinArg,
    size: [30, 30],
    offset,
  },
  ...[40, 50, 60, 70].map((size) => ({
    href: pinArg,
    size: [size, size],
    offset: [offset[0] === 0 ? 0 : size * -1, size * -1],
  })),
];

export const setClusterPinOptions = (pin: string, offset?: number[]) => ({
  clusterIcons: setClusterIcons(pin, offset),
});

export const setPinOptions = (iconImageHref: string = '', iconImageOffset: number[] = [-30, -30]) => ({
  iconImageHref,
  iconImageSize: [30, 30],
  iconImageOffset,
});

export const setPinObj = {
  setClusterPinOptions,
  setPinOptions,
};

export async function fetchData<T extends TBaseItemType = any>(
  coords: number[][],
  apiMethod: Extract<TApiPublicProperties, keyof typeof EGetType>,
  setFn: (data: ExtendType<T>[]) => void,
): Promise<void> {
  try {
    const data = await api[apiMethod](coords);
    if (data?.length) setFn(createFeatureTypes<T>(data));
  } catch (e) {
    console.warn(e);
  }
}

export const isEqual = (data1: any = [], data2: any = []) => JSON.stringify(data1) === JSON.stringify(data2);

export const getPinOptions = (type: EEntityType) => (type === EEntityType.appeals ? [0, -30] : [-30, -30]);
export const getPinType = (type: EEntityType, pinAppeal: string, pinProject: string) =>
  type === EEntityType.appeals ? pinAppeal : pinProject;

type TExtendedIProject = {
  location?: string;
  address?: string;
  subjectName: string;
  stage: string;
} & IProject;

const getLocationWithOutUkraine = (item: any): string =>
  (item?.location || item?.address || '').replace(/украина /i, '');

export const prepareProjectData = (item: IProject): TExtendedIProject => ({
  ...item,
  location: getLocationWithOutUkraine(item),
});

export const prepareAppealData = (item: any): any => ({
  ...item,
  location: getLocationWithOutUkraine(item),
});

export const setYmapsRegionName = (region: any, name: string) => ({
  ...region,
  properties: {
    ...region.properties,
    name,
  },
});

export const uniqueArray = <T = any>(array: T[]) => array.filter((value, index, self) => self.indexOf(value) === index);

export const preparedPopupData = <T>(data: T[]) =>
  data.map((item, ndx) => ({ ...item, ndxId: ndx, isOpen: false, isActive: false }));
